<template>
  <list-container-widget
    id="ideas"
    :title="widgetName"
    :app-id="23"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="ideas" />
    </template>
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="idea"
      @remove-item="deleteItem(currentIdea)"
         @modal-invisible="isUnshareModalVisible = false"
    />
    <!-- Loading -->
    <b-card-body>
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items && items.length > 0 && canList">
        <div ref="container" class="x-scroll-container" @scroll="onScroll">
          <!-- Items Loop -->
          <div>
            <b-card
              v-for="(item, index) in items"
              :key="index"
              no-body
              class="x-scroll-element mx-1 mb-3 p-0 card-standard card-container"
              @click="canView ? selectIdea(item) : null"
            >
              <b-card-body class="pb-2">
                <!-- Current community -->
                <b-card-text
                  class="d-flex  align-items-center w-100 mb-2 text-secondary"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar
                      size="38"
                      :src="getAvatar(collective.logoURL)"
                      :alt="collective.name"
                      class="user-avatar mr-1 float-right"
                    />
                    <span> {{ translateTranslationTable(currentLocale, collective.name) }} </span>
                  </div>
                </b-card-text>

                <!-- Title -->
                <h4 class="mb-1">
                  {{ typeof item.name === 'string' ? item.name : translateTranslationTable(currentLocale, item.name) }}
                </h4>
                <!-- Description object -->
                <template
                  v-if="
                    item.description && typeof item.description !== 'string'
                  "
                >
                  <span
                    v-html="
                      translateTranslationTable(
                        $store.state.locale.currentLocale,
                        item.description
                      )
                    "
                  />
                </template>
                <!-- Description is string -->
                <template v-else-if="item.description">
                  <div
                    class="min-height-3 html-text-ellipsis-4 idea-container"
                  >
                    <span v-html="item.description" />
                  </div>
                </template>
                <!-- No description -->
                <template v-else>
                  <div class="min-height-3" />
                </template>
              </b-card-body>
              <b-card-footer style="border-top: 0px !important">
                <!-- Icons info -->
                <span class="icon-container">
                  <feather-icon
                    size="20"
                    :class="
                      item.likedByMember ? 'profile-likes' : 'profile-icon'
                    "
                    icon="HeartIcon"
                    class="mr-25"
                    @click.stop.prevent="toggleLike(item)"
                  />
                  {{ item.totalLikes || 0 }}
                </span>
                <!-- <span class="ml-2">
                    <feather-icon size="20" icon="MessageSquareIcon" class="mr-25" />
                    {{ item.comments || 0 }}
                  </span> -->
              </b-card-footer>
            </b-card>
          </div>

          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <b-row v-else-if="!canList" class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="ideasPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("no-permisions.message") }}
          </p>
        </b-col>
      </b-row>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="ideasPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- Modal idea info-->
    <b-modal
      id="modal-idea-info"
      v-model="modalIdeaShow"
      cancel-variant="outline-secondary"
      :ok-title="$t('form-generic.ok')"
      :cancel-title="$t('form-generic.cancel')"
      centered
      :title="$t('ideas.modal-create-title')"
      @hidden="isEditing = false"
      @ok="handleEditIdea"
    >
      <feather-icon
        v-if="canEdit"
        icon="Trash2Icon"
        size="20"
        class="text-primary edit-icon float-right ml-1"
        role="button"
        @click="isUnshareModalVisible = true"
      />
      <feather-icon
        v-if="canEdit"
        icon="Edit2Icon"
        size="20"
        class="text-primary edit-icon float-right ml-1"
        role="button"
        @click="handleEditingButton"
      />
      <template v-if="isEditing">
        <label for="title">{{ $t("form-create-item.title") }}:</label>
        <b-input v-model="form.name" style="max-width: 300px"/>

      </template>
      <h4 v-else class="mb-1">
        {{ typeof currentIdea.name === 'string' ? currentIdea.name : translateTranslationTable(currentLocale, currentIdea.name) }}
      </h4>
      <template v-if="isEditing">
      <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
        <quill-editor v-model="form.description" />
      </template>
      <div v-else>
        {{ typeof currentIdea.description === 'string' ? currentIdea.description : translateTranslationTable(currentLocale, currentIdea.description) }}
    </div>
    </b-modal>

    <description-addon v-model="isAppInfoModalVisible" :app-id="28" />
  </list-container-widget>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import DescriptionAddon from "@/@core/widgets/DescriptionAddon.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import IdeasPlaceholder from "@/assets/images/placeholders/light/ideas.svg";
import UnshareItemModal from "@/@core/components/modal/UnshareItemModal.vue";

export default {
  name: "IdeasListWidget",
  components: {
    DescriptionAddon,
    ListContainerWidget,
    UnshareItemModal,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      ideaInput: {},
      form: {},
      isEditing: false,
      currentIdea: {},
      modalIdeaShow: false,
      formError: null,
      scrollEventFired: false,
      isAppInfoModalVisible: false,
      searchInput: "",
      results: [],
      isUnshareModalVisible: false,
    };
  },
  computed: {
    // Load data from store
    itemsData() {
      return this.$store.getters.ideas;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    ideasPlaceholder() {
      return IdeasPlaceholder;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "idea",
        this.loggedMemberRoles,
        this.$store.getters.currentCollective
      );
    },
    canList() {
      return checkPermissions(
        "index",
        "idea",
        this.loggedMemberRoles,
        this.$store.getters.currentCollective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "idea",
        this.loggedMemberRoles,
        this.$store.getters.currentCollective
      );
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    canEdit() {
      return (
        this.collective.isStaff ||
        this.loggedUser?.key === this.item.ownedByMember?.key
      );
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    getAvatar(url) {
      return getImageResource(url);
    },
    async fetchData(force = true) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "ideas",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          perPage: 16,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (this.items?.length < total) {
        this.isLoadingNextPage = true;
        this.lastLoadedPage += 1;
        await this.fetchData();
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreateIdea(bvModalEvt) {
      if (!this.ideaInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "ideas",
            requestConfig: {
              name: this.ideaInput.name,
              description: this.ideaInput.description,
            },
          },
        });
        this.ideaInput = {};
        this.formError = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async deleteItem(item) {
      try {
        const res = await this.$store.dispatch("unshareIdea", {
          item: {
            itemType: "idea",
            modelKey: item.key,
            key: item.key,
            modelName: "idea",
            tableName: "ideas",
          },
        });
        this.modalIdeaShow = false;
      } catch (err) {
        console.log(err);
      }
    },
    selectIdea(idea) {
      this.currentIdea = idea;
      this.modalIdeaShow = true;
    },
    async toggleLike(item) {
      await this.$store.dispatch("toggleLike", {
        itemType: "ideas",
        morphType: "ideas",
        key: item.key,
      });
      this.fetchData();
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
    translateTranslationTable,
    handleEditingButton() {
      this.form = { ...this.currentIdea };
      this.isEditing = true;
    },
    async handleEditIdea() {
      try {
        await this.$store.dispatch("editItem", {
          item: {
            itemType: 'ideas',
            requestConfig: {
              name: this.form.name,
              description: this.form.description,
              ideaKey: this.form.key,
            },
          },
          method: 'put',
          noSet: 'true',
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        await this.fetchData(true);
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style>
.icon-container {
  background-color: white;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
}

.idea-container img {
  width: 100%;
}
.card {
  vertical-align: top !important;
}
.card-container {
  height: 275px;
}
.like {
  background-color: white;
}
</style>
